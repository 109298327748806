<template>
  <div class="search_container">
    <Loading :visible="loading" />
    <Crumb :list="crumbList" :num="pageTotal" @onClick="handleCrumbClick" />
    <Category
      v-show="searchType === 'cid'"
      :activeCid="activeCid"
      :activeSubcid="activeSubcid"
      @onClick="handleCateChange"
      type="taobao"
    />
    <div
      class="search_condition"
      v-if="conditionDataList && conditionDataList.length"
    >
      <ul class="condition" bi-route="shaixuan">
        <li
          :class="{
            active: item.active,
          }"
          v-for="(item, index) in conditionDataList"
          :key="index"
           bi-click="paixu"
           :data-key="item.active ? item.dtitle || item.title : item.title"
           data-pingtai="taobao"
          @click="() => handleConditionChange(index)"
        >
          <span v-if="item.list">
            {{
              (item.list.find((curr) => curr.active == item.active) || {})
                .dtitle
            }}
          </span>
          <span v-else>
            {{ item.active ? item.dtitle || item.title : item.title }}
          </span>
        </li>
        <li class="search_form" v-if="searchType === 'cid'">
          <input
            v-model.number="min_price"
            class="min_price"
            placeholder="￥"
          />
          <span>-</span>
          <input
            v-model.number="max_price"
            class="max_price"
            placeholder="￥"
          />
          <button bi-click="paixu" data-key='价格' @click="handleFilterPrice">确认</button>
          <button @click="handleReset" data-key='价格' class="btn_default">清除</button>
        </li>
      </ul>
      <div class="search_page" v-if="pages">
        <span class="page_btn" @click="() => handlePageChange(page.pageId - 1)">
          <IconFont type="icon-zuojiantou" style="font-size: 14px" />
        </span>
        <span class="search_page_num">
          <b>{{ page.pageId }}</b>
          <span>/{{ pages }}</span>
        </span>
        <span class="page_btn" @click="() => handlePageChange(page.pageId + 1)">
          <IconFont type="icon-youjiantou" style="font-size: 14px" />
        </span>
      </div>
    </div>
    <div class="search_activity" v-if="activityList && activityList.length">
      <ul bi-route="shaixuan">
        <!-- <li class="active">
          <i></i>
          <img
            src="https://img.alicdn.com/imgextra/i3/2053469401/O1CN01R3DrCx2JJi7vnKkBN_!!2053469401.png"
            alt=""
          />
        </li> -->

        <li
          v-for="(item, index) in activityList"
          :key="index"
          :class="{
            active: item.active,
          }"
          bi-click="paixu"
          :data-key="(!item.pic_icon) ? item.title : '验货' "
          data-pingtai="taobao"
          @click="() => handleSxChange(index)"
        >
          <i></i>
          <span v-if="!item.pic || !item.pic_icon">{{ item.title }}</span>
          <span v-else-if="item.pic"><img :src="item.pic" /></span>
          <div v-if="item.pic_icon" style="line-height: 1">
            <img
              style="
                width: 46px;
                height: 14px;
                margin: 0;
                vertical-align: bottom;
              "
              :src="item.pic_icon"
            />
          </div>
        </li>
      </ul>
    </div>
    <div bi-route="goods" v-if="todayList.length == 0" class="search_prod_list">
      <div
        v-for="(item, index) in data"
        :key="item.goodsId || item.id"
        :class="{
          search_card: true,
          isLast: (index + 1) % 5 == 0,
        }"
        bi-click="list"
        :data-index="index"
        :data-gid="item.id || item.goodsId"
        data-pingtai="taobao"
        @click="() => handleItemClick(item)"
      >
        <div class="sp_ins" v-if="item.inspectedGoods"></div>
        <span
          class="sp_text"
          :title="item.specialText && item.specialText[0]"
          v-if="item.specialText && item.specialText[0]"
        >
          {{ item.specialText && item.specialText[0] }}
        </span>
        <!-- <span class="active_tag"></span> -->
        <div v-marketgroup="item">
          <img
            class="main_img"
            v-lazy="`${item.mainPic}_310x310.jpg`"
            alt="商品主图"
          />
        </div>
        <div
          class="search_card_title"
          @click.stop="() => handleTitleClick(item)"
        >
          <img
            :src="getShopImg(item.haitao, item.tchaoshi, item.shopType)"
            alt=""
          />
          <span>{{ item.dtitle || item.title }}</span>
        </div>
        <div class="search_card_tags">
          <span class="tag" v-if="item.activityType === 3">聚划算</span>
          <span class="tag" v-if="item.brandName">{{ item.brandName }}</span>
          <span style="margin-right: 0" class="tag" v-if="item.couponPrice"
            >券{{ item.couponPrice }}元</span
          >
        </div>
        <div class="search_card_price">
          <span>
            <span class="curr">￥</span>
            <span class="price">{{ item.actualPrice }}</span>
          </span>
          <span class="sale"> 已售{{ item.monthSales | salesNum }}件</span>
        </div>
      </div>
    </div>
    <div class="search_list_wrap" v-else>
      <div bi-route="goods" class="search_prod_list_left">
        <div
          v-for="(item, index) in data"
          :key="item.goodsId || item.id"
          :class="{
            search_card: true,
            isLast: (index + 1) % 4 == 0,
          }"
          bi-click="list"
          :data-index="index"
          :data-gid="item.id || item.goodsId"
          data-pingtai="taobao"
          @click="() => handleItemClick(item)"
        >
          <div class="sp_ins" v-if="item.inspectedGoods"></div>
          <span
            class="sp_text"
            :title="item.specialText && item.specialText[0]"
            v-if="item.specialText && item.specialText[0]"
          >
            {{ item.specialText && item.specialText[0] }}
          </span>
          <!-- <span class="active_tag"></span> -->
          <div v-marketgroup="item">
            <img
              class="main_img"
              v-lazy="`${item.mainPic}_310x310.jpg`"
              alt="商品主图"
            />
          </div>
          <div
            class="search_card_title"
            @click.stop="() => handleTitleClick(item)"
          >
            <img
              :src="getShopImg(item.haitao, item.tchaoshi, item.shopType)"
              alt=""
            />
            <span>{{ item.dtitle || item.title}}</span>
          </div>
          <div class="search_card_tags">
            <span class="tag" v-if="item.activityType === 3">聚划算</span>
            <span class="tag" v-if="item.brandName">{{ item.brandName }}</span>
            <span style="margin-right: 0" class="tag" v-if="item.couponPrice"
              >券{{ item.couponPrice }}元</span
            >
          </div>
          <div class="search_card_price">
            <span>
              <span class="curr">￥</span>
              <span class="price">{{ item.actualPrice }}</span>
            </span>
            <span class="sale"> 已售{{ item.monthSales | salesNum(_,1) }}件</span>
          </div>
        </div>
        <div v-if="!(data && data.length)" class="no_goods">
          <img
            src="https://cmsstaticnew.dataoke.com//images/quannogoods.png"
            alt=""
          />
          <p>抱歉~没有找到相关商品,减少一些筛选条件吧</p>
        </div>
      </div>
      <!-- 今日推荐 -->
      <today-side :data="todayList" type="taobao"/>
    </div>
    <Pagination
      :pageSize="page.pageSize"
      :total="pageTotal"
      :current="page.pageId"
      @onchange="handlePageChange"
    />
    <MicroDetail
      @onClose="handleMicroDetailClose"
      v-if="visible"
      :visible="visible"
      :id="microDetailId"
    />
  </div>
</template>

<script>
import IconFont from "@/components/IconFont";
import MicroDetail from "@/components/MicroDetail";
import Loading from "@/components/loading";
import { Crumb, Category, Pagination, TodaySide } from "../components";
import { getTbGoods, getGoodsList, getSuperCategory } from "@/services";
import { guid } from "@/utils/utils";
import { mapGetters } from "vuex";
export default {
  components: {
    IconFont,
    Crumb,
    Category,
    Pagination,
    MicroDetail,
    TodaySide,
    Loading,
  },
  data() {
    return {
      visible: false,
      microDetailId: "",
      min_price: null,
      max_price: null,
      searchType: null, //搜素方式
      loading: true,
      data: [],
      page: {
        pageId: 1,
        pageSize: 100,
      },
      pageTotal: 0,
      sxList: [
        //超级搜索活动筛选
        // 验货
        {
          type: "inspectedGoods",
          value: 1,
          title: "",
          pic_icon: require("../../../assets/search/yh_icon.png"),
        },
        {
          type: "tmall",
          value: 1,
          title: "天猫",
        },
        {
          type: "haitao",
          value: 1,
          title: "天猫国际",
        },
        {
          type: "juHuaSuan",
          value: 1,
          title: "聚划算",
        },

        {
          type: "brand",
          value: 1,
          title: "精选品牌",
        },
        {
          type: "hasCoupon",
          value: 1,
          title: "有券",
        },
      ],
      sxCidList: [
        //商品列表活动筛选
        // 验货
        {
          type: "inspectedGoods",
          title: "",
          value: 1,
          pic_icon: require("../../../assets/search/yh_icon.png"),
        },
        {
          type: "tmall",
          value: 1,
          title: "天猫",
        },
        {
          type: "haitao",
          value: 1,
          title: "天猫国际",
        },
        {
          type: "juHuaSuan",
          value: 1,
          title: "聚划算",
        },
        {
          type: "brand",
          value: 1,
          title: "精选品牌",
        },
      ],
      conditionList: [
        //超级搜条件筛选
        {
          title: "人气",
          type: "sort",
          value: "0",
          active: true,
        },
        // {
        //   title: "最新",
        //   type: "sort",
        //   value: 1,
        //   active: false,
        // },
        {
          title: "销量",
          type: "sort",
          value: ["1", "2"],
          list: [
            {
              dtitle: "销量",
              active: false,
            },
            {
              dtitle: "销量从高到低",
              value: 1,
              active: 2,
            },
            {
              dtitle: "销量从低到高",
              active: 1,
              value: 2,
            },
          ],
          active: false,
        },
        {
          title: "价格",
          type: "sort",
          list: [
            {
              dtitle: "价格",
              active: false,
            },
            {
              dtitle: "价格从高到低",
              value: 5,
              active: 1,
            },
            {
              dtitle: "价格从低到高",
              value: 6,
              active: 2,
            },
          ],
          value: ["5", "6"],
          active: false,
        },
      ],
      conditionCidList: [
        //商品列表搜条件筛选
        {
          title: "人气",
          type: "sort",
          value: "0",
          active: true,
        },
        // {
        //   title: "最新",
        //   type: "sort",
        //   value: 1,
        //   active: false,
        // },
        {
          title: "销量",
          type: "sort",
          value: "2",
          dtitle: "销量从高到低",
          active: false,
        },
        {
          title: "价格",
          type: "sort",
          list: [
            {
              dtitle: "价格",
              active: false,
            },
            {
              dtitle: "价格从低到高",
              value: 6,
              active: 2,
            },
            {
              dtitle: "价格从高到低",
              value: 5,
              active: 1,
            },
          ],
          value: ["5", "6"],
          active: false,
        },
      ],
      crumbList: [], //面包屑
      activeCid: null, //当前类目
      activeSubcid: null, //当前二级类目
      categoryList: [], //类目列表
      paramsMap: [
        //超级搜素字段映射
        {
          name: "activityId",
          mapName: "activityId",
        },
        {
          name: "pageId",
          mapName: "pageId",
        },
        {
          name: "pageSize",
          mapName: "pageSize",
        },
        // 验货
        {
          name: "inspectedGoods",
          mapName: "inspectedGoods",
        },
        {
          name: "keyWords",
          mapName: "kw",
        },
        {
          name: "sort",
          mapName: "sort",
        },
        {
          name: "juHuaSuan",
          mapName: "juHuaSuan",
        },
        {
          name: "brand",
          mapName: "brand",
        },
        {
          name: "haitao",
          mapName: "haitao",
        },
        {
          name: "tmall",
          mapName: "tmall",
        },
        {
          name: "type",
          mapName: "type",
        },
        {
          name: "hasCoupon",
          mapName: "hasCoupon",
        },
      ],
      paramsMapGoods: [
        //商品列表字段映射
        {
          name: "activityId",
          mapName: "activityId",
        },
        {
          name: "pageId",
          mapName: "pageId",
        },
        {
          name: "pageSize",
          mapName: "pageSize",
        },
        {
          name: "juHuaSuan",
          mapName: "juHuaSuan",
        },
        // 验货
        {
          name: "inspectedGoods",
          mapName: "inspectedGoods",
        },
        {
          name: "brand",
          mapName: "brand",
        },
        {
          name: "haitao",
          mapName: "haitao",
        },
        {
          name: "tmall",
          mapName: "tmall",
        },
        {
          name: "sort",
          mapName: "sort",
        },
        {
          name: "cids",
          mapName: "cids",
        },
        {
          name: "subcid",
          mapName: "subcid",
        },
        {
          name: "priceLowerLimit",
          mapName: "priceLowerLimit",
        },
        {
          name: "priceUpperLimit",
          mapName: "priceUpperLimit",
        },
      ],
      todayList: [], //每日半价列表
    };
  },
  beforeCreate() {
    getSuperCategory().then((res) => {
      if (res && res.code === 0) {
        this.categoryList = res.data;
      }
    });
  },

  mounted() {
    this.getTodayListData();
    this.setActivity(this.marketGroupConfig);
    this.init();
  },
  computed: {
    ...mapGetters(["marketGroupConfig", "config"]),
    pages() {
      if (this.pageTotal && this.page.pageSize) {
        return Math.ceil(this.pageTotal / this.page.pageSize);
      }
      return null;
    },
    activityList() {
      switch (this.searchType) {
        case "cid":
          return this.sxCidList;
        case "goods":
          return this.sxList;

        default:
          return [];
      }
    },
    conditionDataList() {
      switch (this.searchType) {
        case "cid":
          return this.conditionCidList;
        case "goods":
          return this.conditionList;

        default:
          return [];
      }
    },
    getDataFun() {
      switch (this.searchType) {
        case "cid":
          return this.getDataList;
        case "goods":
          return this.getData;
        default:
          return () => {};
      }
    },
  },
  methods: {
    setActivity(state) {
      if (!state) {
        return false;
      }
      const activity = Object.entries(state);
      const act = activity.map((res) => ({
        type: "activityId",
        value: res[1].activity_id,
        title: res[1].remark,
        pic: res[1].pc_label,
      }));
      this.sxList = [...act, ...this.sxList];
      this.sxCidList = [...act, ...this.sxCidList];
    },
    init() {
      let queryData = { ...this.$route.query };
      if (queryData.kw) {
        this.searchType = "goods"; //超级搜索
      } else {
        this.searchType = "cid"; //类目，商品列表
      }
      if (!queryData.sort) {
        queryData.sort = 0;
      }
      delete queryData.t;
      delete queryData.from;
      if (this.searchType === "cid") {
        this.activeCid = queryData.cids;
        this.activeSubcid = queryData.subcid;
        //回显活动筛选
        let newList = this.activityList.map((item) => {
          for (let key in queryData) {
            const query =
              typeof queryData[key] != "undefined"
                ? `${queryData[key]}`.split("/")
                : [];
            if (key == item.type && query.includes(String(item.value))) {
              item.active = true;
            } else {
              item.active = false;
            }
          }
          return item;
        });

        this.sxCidList = newList;
        //回显条件筛选
        let newConditionList = this.conditionDataList.map((item) => {
          if (item.value.includes(queryData[item.type])) {
            if (item.list) {
              item.active = this.handleActive(item.active, queryData.sort);
            } else {
              item.active = true;
            }
          } else {
            item.active = false;
          }
          return item;
        });
        this.conditionCidList = newConditionList;
        this.$router.replace({
          path: this.$route.path,
          query: {
            ...queryData,
          },
        });
        this.getDataFun();
      } else if (this.searchType === "goods") {
        let newList = this.activityList.map((item) => {
          for (let key in queryData) {
            const query =
              typeof queryData[key] != "undefined"
                ? `${queryData[key]}`.split("/")
                : [];
            if (key == item.type && query.includes(String(item.value))) {
              item.active = true;
            } else {
              item.active = false;
            }
          }
          return item;
        });
        this.sxList = newList;
        //回显条件筛选
        let newConditionList = this.conditionDataList.map((item) => {
          if (item.value.includes(queryData[item.type])) {
            if (item.list) {
              item.active = this.handleActive(item.active, queryData.sort);
            } else {
              item.active = true;
            }
          } else {
            item.active = false;
          }
          return item;
        });

        this.conditionList = newConditionList;
        this.$router.replace({
          path: this.$route.path,
          query: {
            ...queryData,
          },
        });
        this.getDataFun({ pageId: 1, pageSize: 100 });
      }
    },
    getTodayListData() {
      this.$services.getTodayList().then((res) => {
        if (res && res.code === 1) {
          this.todayList = res.data;
        }
      });
    },
    handleTitleClick(item) {
      // let url = this.$router.resolve({
      //   path: "/l/d",
      //   query: {
      //     id: item.id || item.goodsId,
      //     from: "dtk",
      //   },
      // });
      window.open(`?r=/l/d&id=${item.id || item.goodsId}&from=dtk`, "_blank");
      // window.open(url.href, "_blank");
    },
    handleActive(active, sort) {
      if (sort == 5 || sort == 2) {
        return 1;
      }
      if (sort == 6 || sort == 1) {
        return 2;
      }
      if (!active) {
        return 2;
      } else if (active == 1) {
        return 2;
      } else if (active == 2) {
        return 1;
      }
      return false;
    },
    handleConditionChange(dataIndex) {
      let obj = null;
      let queryData = { ...this.$route.query };
      let list = this.conditionDataList.map((item, index) => {
        if (dataIndex === index) {
          if (item.list) {
            item.active = this.handleActive(item.active);
            obj = item.list.find((curr) => curr.active == item.active);
          } else {
            item.active = true;
            obj = item;
          }
        } else {
          item.active = false;
        }
        return item;
      });
      delete queryData.t;
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...queryData,
          sort: obj.value,
        },
      });
      if (this.searchType === "cid") {
        this.conditionCidList = list;
      } else if (this.searchType === "goods") {
        this.conditionList = list;
      }
      this.getDataFun({ pageId: 1, pageSize: 100 });
    },

    //点击商品
    handleItemClick(item) {
      const urlQuery = { ...this.$route.query };
      if (!this.config.micro_switch) {
        let url = urlQuery.origin_id ? `?r=l/d&u=1&id=${item.id || item.goodsId}&origin_id=${urlQuery.origin_id}&from=dtk` : `?r=l/d&u=1&id=${item.id || item.goodsId}&from=dtk`;
        window.open(url, "_blank");
      } else {
        this.visible = true;
        this.microDetailId = item.id || item.goodsId;
      }
    },
    handleMicroDetailClose() {
      this.visible = false;
      this.microDetailId = null;
    },

    //价格区间筛选
    handleFilterPrice() {
      let queryData = { ...this.$route.query };
      if (Number(this.min_price) > Number(this.max_price)) {
        let minPrice = this.min_price;
        let maxPrice = this.max_price;
        this.max_price = minPrice;
        this.min_price = maxPrice;
      }
      queryData.priceLowerLimit = this.min_price;
      queryData.priceUpperLimit = this.max_price;
      delete queryData.t;
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...queryData,
        },
      });
      this.getDataFun({ pageId: 1, pageSize: 100 });
    },
    handleReset() {
      this.min_price = null;
      this.max_price = null;
      let queryData = { ...this.$route.query };
      delete queryData.priceLowerLimit;
      delete queryData.priceUpperLimit;
      delete queryData.t;
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...queryData,
        },
      });
      this.getDataFun({ pageId: 1, pageSize: 100 });
    },
    //活动筛选
    handleSxChange(dataIndex) {
      let obj = null;
      let queryData = { ...this.$route.query };
      let item = this.activityList[dataIndex];
      // 活动多选
      if (item.type == "activityId") {
        let activityId = queryData.activityId;
        delete queryData.activityId;

        let act =
          typeof activityId != "undefined" ? `${activityId}`.split("/") : [];
        act = act.includes(item.value)
          ? act.filter((res) => res != item.value)
          : [...act, ...[item.value]];

        let list = [...this.activityList];
        list[dataIndex].active = act.includes(item.value);

        if (this.searchType === "cid") {
          this.sxCidList = list;
        } else if (this.searchType === "goods") {
          this.sxList = list;
        }
        this.$router.replace({
          path: "/l",
          query: {
            ...queryData,
            ...(act.length > 0 ? { activityId: act.join("/") } : {}),
          },
        });
        this.getDataFun({ pageId: 1, pageSize: 100 });
        return false;
      }

      if (item.active) {
        let list = this.activityList.map((item, index) => {
          var isValue = `${queryData[item.type]}`
            .split(",")
            .includes(item.value);
          if (dataIndex === index) {
            item.active = false;
            obj = item;
          }
          return item;
        });

        delete queryData[obj.type];
        delete queryData.t;
        this.$router.replace({
          path: this.$route.path,
          query: {
            ...queryData,
          },
        });
        if (this.searchType === "cid") {
          this.sxCidList = list;
        } else if (this.searchType === "goods") {
          this.sxList = list;
        }
        this.getDataFun({ pageId: 1, pageSize: 100 });
        return;
      }
      let list = this.activityList.map((item, index) => {
        if (dataIndex === index) {
          item.active = true;
          obj = item;
        }
        return item;
      });
      delete queryData.t;
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...queryData,
          [obj.type]: obj.value,
        },
      });
      if (this.searchType === "cid") {
        this.sxCidList = list;
      } else if (this.searchType === "goods") {
        this.sxList = list;
      }

      this.getDataFun({ pageId: 1, pageSize: 100 });
    },
    getData(page = this.page, params) {
      if (!this.config.site_id) {
        return
      }
      this.loading = true;
      let configParams = {}
      if (this.config) {
        if (Number(this.config.tmall)) {
          configParams.tmall = Number(this.config.is_tmall);
        }
        if (Number(this.config.is_commnum)) {
          configParams.startTkRate = Number(this.config.set_commnum);
        }
        if (Number(this.config.is_filter)) {
          configParams.hasCoupon = 1;
        }
      }
      const urlQuery = { ...this.$route.query };
      const urlParams = {
        ...urlQuery,
      };
      const obj = {
        type: 0,
        ...page,
        ...params,
        ...urlParams,
      };
      const newParams = {};
      this.paramsMap.forEach((item) => {
        if (obj[item.mapName] || obj[item.mapName] == 0) {
          newParams[item.name] = obj[item.mapName];
        }
      });
      this.updateClumbList();
      if (newParams.activityId) {
        newParams.activityId = `${newParams.activityId}`.replace(
          /\%2F|\//g,
          ","
        );
      }
      let endParams = {
        ...newParams,
        ...configParams
      }
      getTbGoods({
        ...endParams,...{
          keyWords:decodeURIComponent(endParams.keyWords||'')
        }
      })
        .then((res) => {
          if (res && res.code === 0 && res.data) {
            this.data = res.data.list;
            this.page = {
              ...this.page,
              pageId: Number(res.data.pageId),
            };
            this.pageTotal =
              res.data.totalNum > 10000 ? 10000 : res.data.totalNum;
          } else {
            this.data = [];
            this.pageTotal = 0;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    //商品列表
    getDataList(page = this.page, params) {
      if (!this.config.site_id) {
        return;
      }
      this.loading = true;
      let configParams = {}
      if (this.config) {
        if (Number(this.config.tmall)) {
          if (Number(this.config.is_tmall)) {
            configParams.tmall = Number(this.config.is_tmall);
          }
        }
        if (Number(this.config.is_commnum)) {
          configParams.commissionRateLowerLimit = Number(this.config.set_commnum);
        }
        if (Number(this.config.is_filter)) {
          configParams.hasCoupon = 1;
        }
      }
      const urlQuery = { ...this.$route.query };
      const urlParams = {
        ...urlQuery,
      };
      const obj = {
        ...page,
        ...params,
        ...urlParams,
      };
      const newParams = {};
      this.paramsMapGoods.forEach((item) => {
        if (obj[item.mapName]) {
          newParams[item.name] = obj[item.mapName];
        }
      });
      if (newParams.subcid) {
        delete newParams.cids;
      }
      this.updateClumbList();

      if (newParams.activityId) {
        newParams.activityId = `${newParams.activityId}`.replace(
          /\%2F|\//g,
          ","
        );
      }
      let endParams = {
        ...newParams,
        ...configParams
      }
      getGoodsList({
        ...endParams,...{
          keyWords:decodeURIComponent(endParams.keyWords||'')
        }
      })
        .then((res) => {
          if (res && res.code === 0 && res.data) {
            this.data = res.data.list;
            this.page = {
              ...this.page,
              pageId: page.pageId,
            };
            this.pageTotal =
              res.data.totalNum > 10000 ? 10000 : res.data.totalNum;
          } else {
            this.data = [];
            this.pageTotal = 0;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    getShopImg(haitao, tchaoshi, shopType) {
      if (haitao == 1) return require("../../../assets/search/prod_icon2.png");
      if (tchaoshi == 1)
        return require("../../../assets/search/prod_icon3.png");
      if (shopType == 1)
        return require("../../../assets/search/prod_icon1.png");
      return require("../../../assets/search/prod_icon1.png");
    },
    handlePageChange(current) {
      if (current < 1 || current > this.pages) return;
      const pageParams = {
        ...this.page,
        pageId: current,
      };
      
      this.getDataFun(pageParams);
    },
    handleCateChange(params) {
      const obj = {
        [params.type]: params.value,
      };
      let queryData = { ...this.$route.query };

      if (params.type == "cids") {
        this.activeCid = params.value;
        delete queryData.subcid;
      } else {
        this.activeSubcid = params.value;
        // delete queryData.cids
      }
      queryData[params.type] = params.value;
      delete queryData.t;
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...queryData,
        },
      });
      this.getDataFun({ pageId: 1, pageSize: 100 }, obj);
    },
    handleCrumbClick(item) {
      let queryData = { ...this.$route.query };

      if (item.label === "活动") {
        let list = this.activityList.map((curr) => {
          if (curr.type === item.type && curr.value == item.value) {
            curr.active = false;
          }
          return curr;
        });

        if (this.searchType === "cid") {
          this.sxCidList = list;
        } else if (this.searchType === "goods") {
          this.sxList = list;
        }
      }
      if (item.type === "price") {
        delete queryData["priceLowerLimit"];
        delete queryData["priceUpperLimit"];
        this.min_price = null;
        this.max_price = null;
      } else if (item.type === "activityId") {
        let act =
          typeof queryData.activityId != "undefined"
            ? `${queryData.activityId}`.split("/")
            : [];
        act = act.filter((res) => res != item.value);
        if (act.length > 0) {
          queryData[item.type] = act.join("/");
        } else {
          delete queryData[item.type];
        }
      } else {
        delete queryData[item.type];
      }

      if (item.type === "cids") {
        this.activeCid = null;
        this.activeSubcid = null;
        delete queryData.cids;
        delete queryData.subcid;
      }
      if (item.type === "subcid") {
        this.activeSubcid = null;
      }
      delete queryData.t;

      this.$router.replace({
        path: this.$route.path,
        query: {
          ...queryData,
        },
      });
      this.getDataFun({ pageId: 1, pageSize: 100 });
    },
    updateClumbList() {
      let queryData = { ...this.$route.query };
      let list = [];
      // 活动 多选
      this.activityList.forEach((item) => {
        let obj = {};
        // if(item.type == "activityId" && ){
        //   obj = {
        //       name: item.title,
        //       value: item.value,
        //       label: "活动",
        //       id: guid(),
        //       type: item.type,
        //     };
        //   return false;
        // }
        for (let key in queryData) {
          let act =
            typeof queryData[key] != "undefined"
              ? `${queryData[key]}`.split("/")
              : [];
          if (key == item.type && act.includes(item.value)) {
            obj = {
              name: item.title,
              value: item.value,
              label: "活动",
              id: guid(),
              type: item.type,
            };
            list.push(obj);
          }
        }
      });
      if (queryData.cids) {
        let obj = this.categoryList.find((item) => item.cid == queryData.cids);
        if (obj) {
          list.push({
            name: obj.cname,
            value: obj.cid,
            label: "类目",
            id: guid(),
            type: "cids",
          });
          if (queryData.subcid) {
            let newObj = obj.subcategories.find(
              (item) => item.subcid == queryData.subcid
            );
            if (newObj) {
              list.push({
                name: newObj.subcname,
                value: newObj.subcid,
                label: obj.cname,
                id: guid(),
                type: "subcid",
              });
            }
          }
        }
      }

      if (queryData.priceLowerLimit && queryData.priceUpperLimit) {
        list.push({
          name: `${queryData.priceLowerLimit}-${queryData.priceUpperLimit}`,
          value: `${queryData.priceLowerLimit}-${queryData.priceUpperLimit}`,
          label: "价格",
          id: guid(),
          type: "price",
        });
        this.min_price = queryData.priceLowerLimit;
        this.max_price = queryData.priceUpperLimit;
      }

      this.crumbList = list;
    },
  },
  beforeRouteUpdate(to, from, next) {
    // ...
    next();
    if (to.query.t || to.query.from === "head") {
      let newList = this.activityList.map((item) => {
        item.active = false;
        return item;
      });
      this.activityList = newList;
      this.init();
    }
  },
  watch: {
    categoryList() {
      this.updateClumbList();
    },
    marketGroupConfig(state){
      this.setActivity(state)
    },
    config() {
      this.init();

    }
  },
};
</script>

<style scoped lang="less">
@import url("../index.less");
.search_condition {
  background-color: #fff;
  height: 40px;
  width: 100%;
  line-height: 40px;
  position: relative;
  border: 1px solid rgba(241, 241, 241, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  .condition {
    display: flex;
    max-width: 1108px;
    li {
      line-height: 40px;
      height: 39px;
      border-right: #f1f1f1 1px solid;
      padding: 0 20px;
      color: #666;
      position: relative;
      cursor: pointer;
      &:hover {
        color: #fe2e54;
      }
    }
    li.active {
      color: red;
      background: #fff;
      line-height: 40px;
      height: 40px;
    }
    li.search_form {
      width: 235px;
      margin-top: 1px;
      position: relative;
      z-index: 9;
      border-right: none;
      &:hover {
        background: #fff;
        box-shadow: 0 0 6px 0 rgba(170, 170, 170, 0.3);
        button {
          display: inline-block;
        }
      }
      span {
        height: 24px;
        line-height: 22px;
        padding: 0 2px;
      }
      .min_price,
      .max_price {
        width: 60px;
        height: 22px;
        line-height: 22px;
        padding: 0 4px;
        box-sizing: border-box;
        color: #999;
        border: 1px solid #eee;
        font-size: 12px;
        outline: 0;
      }
      button {
        display: none;
        width: 40px;
        height: 22px;
        border-radius: 2px;
        background: #ff6255;
        color: #fff;
        text-align: center;
        line-height: 22px;
        border: none;
        outline: 0;
        font-size: 12px;
        margin-left: 10px;
        cursor: pointer;
      }
      button.btn_default {
        background-color: transparent;
        color: #ff6255;
      }
    }
  }
  .search_page {
    text-align: center;
    height: 26px;
    line-height: 26px;
    margin-right: 18px;
    b {
      color: #ff6255;
    }
    .page_btn {
      cursor: pointer;
      &:hover {
        color: #ff6255;
      }
    }
    span.page_btn.disable {
      cursor: default;
      &:hover {
        color: transparent;
      }
    }
    .search_page_num {
      font-size: 14px;
      margin: 0 8px;
      color: #666;
    }
  }
}
.search_activity {
  height: 40px;
  background: #fafafa;
  border: 1px solid rgba(241, 241, 241, 1);
  border-top: none;
  ul {
    display: flex;
    align-items: center;
    height: 100%;
    li.active {
      i {
        width: 12px;
        height: 12px;
        background: url("../../../assets/search/actcheck.png") center
          no-repeat#fff;
        background-size: 8px 6px;
        border: 1px solid #fe2e54;
      }
      span {
        color: #fe2e54;
      }
    }
    li {
      list-style: none;
      padding: 0 15px;
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      &:hover {
        i {
          border: 1px solid #fe2e54;
        }
        span {
          color: #fe2e54;
        }
      }
      i {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 2px;
        border: 1px solid rgba(211, 211, 211, 1);
        background: #fff;
      }
      img {
        height: 12px;
        margin-left: 6px;
        vertical-align: inherit;
      }
      span {
        margin-left: 6px;
        font-size: 12px;
      }
    }
  }
}
.search_list_wrap {
  width: 1200px;
  display: flex;
  .search_prod_list_left {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
    margin-top: 12px;
    height: 100%;
    .search_card.isLast {
      margin: 0;
    }
    .search_card {
      padding: 20px 15px 0;
      position: relative;
      text-decoration: none;
      width: 230px;
      height: 315px;
      background: #fff;
      flex-shrink: 0;
      margin-bottom: 12px;
      list-style: none;
      box-sizing: border-box;
      line-height: 24px;
      margin-right: 12px;
      &:hover {
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
        .search_card_title span {
          color: #fe2e54 !important;
        }
      }
      .sp_ins {
        width: 75px;
        height: 21px;
        background: url("../../../assets/search/yh_icon1.png") no-repeat;
        background-size: 100% 100%;
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 99;
      }
      span.sp_text {
        max-width: 174px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 20px;
        top: 44px;
        line-height: 20px;
        padding: 0 12px;
        background: #ff6594;
        background: linear-gradient(90deg, #ff6594 0, #ff2049 100%);
        border-radius: 0 100px 100px 0;
        overflow: hidden;
        position: absolute;
        left: 20px;
        font-size: 12px;
        z-index: 99;
        color: #fff;
        box-sizing: border-box;
      }
      span.active_tag {
        width: 190px;
        height: 35px;
        top: 176px;
        text-align: right;
        line-height: 18px;
        padding: 17px 5px 0 0;
        background: url("https://img.alicdn.com/imgextra/i1/2053469401/O1CN013InyzO2JJi7taWujh_!!2053469401.png")
          0px 0px / contain no-repeat;
        position: absolute;
        left: 20px;
        font-size: 12px;
        color: #fff;
        box-sizing: border-box;
      }
      img.main_img {
        width: 190px;
        height: 190px;
        display: block;
        margin: 0 auto;
      }
      .search_card_title {
        display: flex;
        overflow: hidden;
        flex-wrap: nowrap;
        align-items: center;
        margin-top: 9px;
        span {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          margin-left: 2px;
          font-size: 14px;
          color: #333;
          line-height: 20px;
        }
        img {
          width: 14px;
          height: 14px;
          flex-shrink: 0;
        }
      }
      .search_card_tags {
        min-height: 16px;
        display: flex;
        align-items: center;
        margin-top: 9px;
        span.tag {
          height: 16px;
          background: #ffe8e7;
          border-radius: 2px;
          padding: 0 3px;
          font-size: 12px;
          color: #ff2828;
          line-height: 16px;
          margin-right: 5px;
        }
      }
      .search_card_price {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-top: 9px;
        .curr {
          font-size: 18px;
          color: #fe2e2e;
        }
        .price {
          font-size: 28px;
          color: #fe2e2e;
        }
        .sale {
          display: flex;
          font-size: 14px;
          color: #ababab;
        }
      }
    }
  }
}
.search_prod_list {
  width: 1200px;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  margin-top: 12px;
  .search_card.isLast {
    margin: 0;
  }
  .search_card {
    padding: 20px 15px 0;
    position: relative;
    text-decoration: none;
    width: 230px;
    height: 315px;
    background: #fff;
    flex-shrink: 0;
    margin-bottom: 12px;
    list-style: none;
    box-sizing: border-box;
    line-height: 24px;
    margin-right: 12px;
    &:hover {
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
      .search_card_title span {
        color: #fe2e54 !important;
      }
    }
    .sp_ins {
      width: 75px;
      height: 21px;
      background: url("../../../assets/search/yh_icon1.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
      top: 20px;
      left: 20px;
      z-index: 99;
    }
    span.sp_text {
      max-width: 174px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 20px;
      top: 44px;
      line-height: 20px;
      padding: 0 12px;
      background: #ff6594;
      background: linear-gradient(90deg, #ff6594 0, #ff2049 100%);
      border-radius: 0 100px 100px 0;
      overflow: hidden;
      position: absolute;
      left: 20px;
      font-size: 12px;
      z-index: 99;
      color: #fff;
      box-sizing: border-box;
    }
    span.active_tag {
      width: 190px;
      height: 35px;
      top: 176px;
      text-align: right;
      line-height: 18px;
      padding: 17px 5px 0 0;
      background: url("https://img.alicdn.com/imgextra/i1/2053469401/O1CN013InyzO2JJi7taWujh_!!2053469401.png")
        0px 0px / contain no-repeat;
      position: absolute;
      left: 20px;
      font-size: 12px;
      color: #fff;
      box-sizing: border-box;
    }
    img.main_img {
      width: 190px;
      height: 190px;
      display: block;
      margin: 0 auto;
    }
    .search_card_title {
      display: flex;
      overflow: hidden;
      flex-wrap: nowrap;
      align-items: center;
      margin-top: 9px;
      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-left: 2px;
        font-size: 14px;
        color: #333;
        line-height: 20px;
      }
      img {
        width: 14px;
        height: 14px;
        flex-shrink: 0;
      }
    }
    .search_card_tags {
      min-height: 16px;
      display: flex;
      align-items: center;
      margin-top: 9px;
      span.tag {
        height: 16px;
        background: #ffe8e7;
        border-radius: 2px;
        padding: 0 3px;
        font-size: 12px;
        color: #ff2828;
        line-height: 16px;
        margin-right: 5px;
      }
    }
    .search_card_price {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: 9px;
      .curr {
        font-size: 18px;
        color: #fe2e2e;
      }
      .price {
        font-size: 28px;
        color: #fe2e2e;
      }
      .sale {
        display: flex;
        font-size: 14px;
        color: #ababab;
      }
    }
  }
}
.no_goods {
  width: 1000px;
  padding: 0 0 50px;
  margin: 100px auto;
  text-align: center;
  img {
    width: 115px;
    height: 103px;
  }
  p {
    margin: 5px 0;
  }
}
</style>
