var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search_container" },
    [
      _c("Loading", { attrs: { visible: _vm.loading } }),
      _c("Crumb", {
        attrs: { list: _vm.crumbList, num: _vm.pageTotal },
        on: { onClick: _vm.handleCrumbClick }
      }),
      _c("Category", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.searchType === "cid",
            expression: "searchType === 'cid'"
          }
        ],
        attrs: {
          activeCid: _vm.activeCid,
          activeSubcid: _vm.activeSubcid,
          type: "taobao"
        },
        on: { onClick: _vm.handleCateChange }
      }),
      _vm.conditionDataList && _vm.conditionDataList.length
        ? _c("div", { staticClass: "search_condition" }, [
            _c(
              "ul",
              { staticClass: "condition", attrs: { "bi-route": "shaixuan" } },
              [
                _vm._l(_vm.conditionDataList, function(item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      class: {
                        active: item.active
                      },
                      attrs: {
                        "bi-click": "paixu",
                        "data-key": item.active
                          ? item.dtitle || item.title
                          : item.title,
                        "data-pingtai": "taobao"
                      },
                      on: {
                        click: function() {
                          return _vm.handleConditionChange(index)
                        }
                      }
                    },
                    [
                      item.list
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  (
                                    item.list.find(function(curr) {
                                      return curr.active == item.active
                                    }) || {}
                                  ).dtitle
                                ) +
                                " "
                            )
                          ])
                        : _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.active
                                    ? item.dtitle || item.title
                                    : item.title
                                ) +
                                " "
                            )
                          ])
                    ]
                  )
                }),
                _vm.searchType === "cid"
                  ? _c("li", { staticClass: "search_form" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.min_price,
                            expression: "min_price",
                            modifiers: { number: true }
                          }
                        ],
                        staticClass: "min_price",
                        attrs: { placeholder: "￥" },
                        domProps: { value: _vm.min_price },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.min_price = _vm._n($event.target.value)
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      }),
                      _c("span", [_vm._v("-")]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.max_price,
                            expression: "max_price",
                            modifiers: { number: true }
                          }
                        ],
                        staticClass: "max_price",
                        attrs: { placeholder: "￥" },
                        domProps: { value: _vm.max_price },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.max_price = _vm._n($event.target.value)
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      }),
                      _c(
                        "button",
                        {
                          attrs: { "bi-click": "paixu", "data-key": "价格" },
                          on: { click: _vm.handleFilterPrice }
                        },
                        [_vm._v("确认")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn_default",
                          attrs: { "data-key": "价格" },
                          on: { click: _vm.handleReset }
                        },
                        [_vm._v("清除")]
                      )
                    ])
                  : _vm._e()
              ],
              2
            ),
            _vm.pages
              ? _c("div", { staticClass: "search_page" }, [
                  _c(
                    "span",
                    {
                      staticClass: "page_btn",
                      on: {
                        click: function() {
                          return _vm.handlePageChange(_vm.page.pageId - 1)
                        }
                      }
                    },
                    [
                      _c("IconFont", {
                        staticStyle: { "font-size": "14px" },
                        attrs: { type: "icon-zuojiantou" }
                      })
                    ],
                    1
                  ),
                  _c("span", { staticClass: "search_page_num" }, [
                    _c("b", [_vm._v(_vm._s(_vm.page.pageId))]),
                    _c("span", [_vm._v("/" + _vm._s(_vm.pages))])
                  ]),
                  _c(
                    "span",
                    {
                      staticClass: "page_btn",
                      on: {
                        click: function() {
                          return _vm.handlePageChange(_vm.page.pageId + 1)
                        }
                      }
                    },
                    [
                      _c("IconFont", {
                        staticStyle: { "font-size": "14px" },
                        attrs: { type: "icon-youjiantou" }
                      })
                    ],
                    1
                  )
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm.activityList && _vm.activityList.length
        ? _c("div", { staticClass: "search_activity" }, [
            _c(
              "ul",
              { attrs: { "bi-route": "shaixuan" } },
              _vm._l(_vm.activityList, function(item, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    class: {
                      active: item.active
                    },
                    attrs: {
                      "bi-click": "paixu",
                      "data-key": !item.pic_icon ? item.title : "验货",
                      "data-pingtai": "taobao"
                    },
                    on: {
                      click: function() {
                        return _vm.handleSxChange(index)
                      }
                    }
                  },
                  [
                    _c("i"),
                    !item.pic || !item.pic_icon
                      ? _c("span", [_vm._v(_vm._s(item.title))])
                      : item.pic
                      ? _c("span", [_c("img", { attrs: { src: item.pic } })])
                      : _vm._e(),
                    item.pic_icon
                      ? _c("div", { staticStyle: { "line-height": "1" } }, [
                          _c("img", {
                            staticStyle: {
                              width: "46px",
                              height: "14px",
                              margin: "0",
                              "vertical-align": "bottom"
                            },
                            attrs: { src: item.pic_icon }
                          })
                        ])
                      : _vm._e()
                  ]
                )
              }),
              0
            )
          ])
        : _vm._e(),
      _vm.todayList.length == 0
        ? _c(
            "div",
            { staticClass: "search_prod_list", attrs: { "bi-route": "goods" } },
            _vm._l(_vm.data, function(item, index) {
              return _c(
                "div",
                {
                  key: item.goodsId || item.id,
                  class: {
                    search_card: true,
                    isLast: (index + 1) % 5 == 0
                  },
                  attrs: {
                    "bi-click": "list",
                    "data-index": index,
                    "data-gid": item.id || item.goodsId,
                    "data-pingtai": "taobao"
                  },
                  on: {
                    click: function() {
                      return _vm.handleItemClick(item)
                    }
                  }
                },
                [
                  item.inspectedGoods
                    ? _c("div", { staticClass: "sp_ins" })
                    : _vm._e(),
                  item.specialText && item.specialText[0]
                    ? _c(
                        "span",
                        {
                          staticClass: "sp_text",
                          attrs: {
                            title: item.specialText && item.specialText[0]
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(item.specialText && item.specialText[0]) +
                              " "
                          )
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "marketgroup",
                          rawName: "v-marketgroup",
                          value: item,
                          expression: "item"
                        }
                      ]
                    },
                    [
                      _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: item.mainPic + "_310x310.jpg",
                            expression: "`${item.mainPic}_310x310.jpg`"
                          }
                        ],
                        staticClass: "main_img",
                        attrs: { alt: "商品主图" }
                      })
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "search_card_title",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return function() {
                            return _vm.handleTitleClick(item)
                          }.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.getShopImg(
                            item.haitao,
                            item.tchaoshi,
                            item.shopType
                          ),
                          alt: ""
                        }
                      }),
                      _c("span", [_vm._v(_vm._s(item.dtitle || item.title))])
                    ]
                  ),
                  _c("div", { staticClass: "search_card_tags" }, [
                    item.activityType === 3
                      ? _c("span", { staticClass: "tag" }, [_vm._v("聚划算")])
                      : _vm._e(),
                    item.brandName
                      ? _c("span", { staticClass: "tag" }, [
                          _vm._v(_vm._s(item.brandName))
                        ])
                      : _vm._e(),
                    item.couponPrice
                      ? _c(
                          "span",
                          {
                            staticClass: "tag",
                            staticStyle: { "margin-right": "0" }
                          },
                          [_vm._v("券" + _vm._s(item.couponPrice) + "元")]
                        )
                      : _vm._e()
                  ]),
                  _c("div", { staticClass: "search_card_price" }, [
                    _c("span", [
                      _c("span", { staticClass: "curr" }, [_vm._v("￥")]),
                      _c("span", { staticClass: "price" }, [
                        _vm._v(_vm._s(item.actualPrice))
                      ])
                    ]),
                    _c("span", { staticClass: "sale" }, [
                      _vm._v(
                        " 已售" +
                          _vm._s(_vm._f("salesNum")(item.monthSales)) +
                          "件"
                      )
                    ])
                  ])
                ]
              )
            }),
            0
          )
        : _c(
            "div",
            { staticClass: "search_list_wrap" },
            [
              _c(
                "div",
                {
                  staticClass: "search_prod_list_left",
                  attrs: { "bi-route": "goods" }
                },
                [
                  _vm._l(_vm.data, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: item.goodsId || item.id,
                        class: {
                          search_card: true,
                          isLast: (index + 1) % 4 == 0
                        },
                        attrs: {
                          "bi-click": "list",
                          "data-index": index,
                          "data-gid": item.id || item.goodsId,
                          "data-pingtai": "taobao"
                        },
                        on: {
                          click: function() {
                            return _vm.handleItemClick(item)
                          }
                        }
                      },
                      [
                        item.inspectedGoods
                          ? _c("div", { staticClass: "sp_ins" })
                          : _vm._e(),
                        item.specialText && item.specialText[0]
                          ? _c(
                              "span",
                              {
                                staticClass: "sp_text",
                                attrs: {
                                  title: item.specialText && item.specialText[0]
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.specialText && item.specialText[0]
                                    ) +
                                    " "
                                )
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "marketgroup",
                                rawName: "v-marketgroup",
                                value: item,
                                expression: "item"
                              }
                            ]
                          },
                          [
                            _c("img", {
                              directives: [
                                {
                                  name: "lazy",
                                  rawName: "v-lazy",
                                  value: item.mainPic + "_310x310.jpg",
                                  expression: "`${item.mainPic}_310x310.jpg`"
                                }
                              ],
                              staticClass: "main_img",
                              attrs: { alt: "商品主图" }
                            })
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "search_card_title",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return function() {
                                  return _vm.handleTitleClick(item)
                                }.apply(null, arguments)
                              }
                            }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: _vm.getShopImg(
                                  item.haitao,
                                  item.tchaoshi,
                                  item.shopType
                                ),
                                alt: ""
                              }
                            }),
                            _c("span", [
                              _vm._v(_vm._s(item.dtitle || item.title))
                            ])
                          ]
                        ),
                        _c("div", { staticClass: "search_card_tags" }, [
                          item.activityType === 3
                            ? _c("span", { staticClass: "tag" }, [
                                _vm._v("聚划算")
                              ])
                            : _vm._e(),
                          item.brandName
                            ? _c("span", { staticClass: "tag" }, [
                                _vm._v(_vm._s(item.brandName))
                              ])
                            : _vm._e(),
                          item.couponPrice
                            ? _c(
                                "span",
                                {
                                  staticClass: "tag",
                                  staticStyle: { "margin-right": "0" }
                                },
                                [_vm._v("券" + _vm._s(item.couponPrice) + "元")]
                              )
                            : _vm._e()
                        ]),
                        _c("div", { staticClass: "search_card_price" }, [
                          _c("span", [
                            _c("span", { staticClass: "curr" }, [_vm._v("￥")]),
                            _c("span", { staticClass: "price" }, [
                              _vm._v(_vm._s(item.actualPrice))
                            ])
                          ]),
                          _c("span", { staticClass: "sale" }, [
                            _vm._v(
                              " 已售" +
                                _vm._s(
                                  _vm._f("salesNum")(item.monthSales, _vm._, 1)
                                ) +
                                "件"
                            )
                          ])
                        ])
                      ]
                    )
                  }),
                  !(_vm.data && _vm.data.length)
                    ? _c("div", { staticClass: "no_goods" }, [
                        _c("img", {
                          attrs: {
                            src:
                              "https://cmsstaticnew.dataoke.com//images/quannogoods.png",
                            alt: ""
                          }
                        }),
                        _c("p", [
                          _vm._v("抱歉~没有找到相关商品,减少一些筛选条件吧")
                        ])
                      ])
                    : _vm._e()
                ],
                2
              ),
              _c("today-side", {
                attrs: { data: _vm.todayList, type: "taobao" }
              })
            ],
            1
          ),
      _c("Pagination", {
        attrs: {
          pageSize: _vm.page.pageSize,
          total: _vm.pageTotal,
          current: _vm.page.pageId
        },
        on: { onchange: _vm.handlePageChange }
      }),
      _vm.visible
        ? _c("MicroDetail", {
            attrs: { visible: _vm.visible, id: _vm.microDetailId },
            on: { onClose: _vm.handleMicroDetailClose }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }